<template>
  <div class="view h-100">
    <div class="strip-bg strip-bg--condensed strip-bg--blue">
      <div class="container pos-r flex space-between h-100 valign-center" style="min-height: 12rem;">
        <h2 class="font--biggest h-100 m-0 color-white">Verkäufe</h2>

        <div>
          <span class="color-white m-r-half">Zeige Charts als:</span>
          <el-radio-group class="m-r-1" v-model="charts.displayMode" @change="requestInitData()">
            <el-radio-button label="month">Monat</el-radio-button>
            <el-radio-button label="year">Jahr</el-radio-button>
          </el-radio-group>

          <DatePicker @changeDate="onChangeDateRange" />
        </div>
      </div>
    </div>

    <div class="container pos-r m-b-3">
      <!-- Erste Reihe  -->
      <el-row :gutter="20" type="flex" style="top: -2rem;" class="flex-wrap">
        <el-col :xs="24" :sm="12" :md="6">
          <el-card>
            <CardKPI :value="totalSumNetMonth" :percentage="percentageTotalNet">
              <template v-slot:header>
                <span>Gesamt Netto-Umsatz</span>
              </template>
              <template v-slot:footer>
                <div class="flex space-between a-center m-t-2">
                  <span class="font--small">
                    <span>Offen:</span>
                    <span class="m-l-half">{{ sumOpenInvoices }} €</span>
                  </span>

                  <div
                    class="kpi__trend"
                    :class="{
                      'color-success': incomeTrend.net > 0,
                      'color-danger': incomeTrend.net < 0
                    }"
                  >
                    <span class="kpi__trend-icon">
                      <i class="material-icons" v-if="incomeTrend.net > 0">arrow_upwards</i>
                      <i class="material-icons" v-else>arrow_downwards</i>
                    </span>
                    <span class="kpi__trend-value">{{ incomeTrend.net }}</span>
                  </div>
                </div>
              </template>
            </CardKPI>
          </el-card>
        </el-col>

        <el-col :xs="24" :sm="12" :md="6">
          <el-card>
            <CardKPI :value="totalSumVoucher">
              <template v-slot:header>
                <span>Gesamtausgaben</span>
              </template>
              <template v-slot:footer>
                <div class="flex space-between a-center m-t-2">
                  <span class="font--small">
                    <span>Offen:</span>
                    <span class="m-l-half">{{ sumOpenVouchers }} €</span>
                  </span>

                  <div
                    class="kpi__trend"
                    :class="{
                      'color-success': outgoingsTrend.net < 0,
                      'color-danger': outgoingsTrend.net > 0
                    }"
                  >
                    <span class="kpi__trend-icon">
                      <i class="material-icons" v-if="outgoingsTrend.net > 0">arrow_upwards</i>
                      <i class="material-icons" v-else>arrow_downwards</i>
                    </span>
                    <span class="kpi__trend-value">{{ outgoingsTrend.net }}</span>
                  </div>
                </div>
              </template>
            </CardKPI>
          </el-card>
        </el-col>

        <el-col :xs="24" :sm="12" :md="6">
          <el-card class="h-100">
            <div class="flex flex-column space-between">
              <header class="text-center font--regular-plus m-b-0 color-success font--bold">
                Top Kunden
              </header>
              <ol v-if="topCustomersPerSumNet.length">
                <li v-for="(customer, index) in topCustomersPerSumNet" :key="index">
                  <div class="flex space-between">
                    {{ customer.name }}
                    <span class="color-gray font--small" style="min-width: 3rem;">{{ Number(customer.sumNet).toFixed(0) }} €</span>
                  </div>
                </li>
              </ol>
            </div>
          </el-card>
        </el-col>

        <el-col :xs="24" :sm="12" :md="6">
          <el-card class="h-100">
            <div class="flex flex-column space-between">
              <header class="text-center font--regular-plus m-b-0 color-danger font--bold">
                Top Ausgaben
              </header>
              <ol v-if="topCategoriesPerSumNet.length">
                <li v-for="(customer, index) in topCategoriesPerSumNet" :key="index">
                  <div class="flex space-between">
                    {{ customer.name }}
                    <span class="color-gray font--small">{{ Number(customer.sumNet).toFixed(0) }} €</span>
                  </div>
                </li>
              </ol>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <h2>
        <span>Umsatz</span>
      </h2>
      <!-- Zweite Reihe -->
      <el-row :gutter="20" type="flex" class="m-t-1">
        <el-col :xs="24" :sm="12">
          <el-card class="card--chart" v-loading="!incomeChartsDataLoaded">
            <BarChart
              :chart-data="incomeChartsData"
              :options="barChartOptions"
              :width="charts.width"
              :height="charts.height"
              v-if="incomeChartsDataLoaded"
            />
          </el-card>
        </el-col>

        <el-col :xs="24" :sm="12">
          <el-card class="card--chart" v-loading="!incomeCharts.customers.loaded">
            <PieChart
              :chart-data="charts.income.customers.chartjs"
              :options="doughnutChartOptions"
              :width="charts.width"
              :height="charts.height"
              v-if="incomeCharts.customers.loaded"
            />
          </el-card>
        </el-col>
      </el-row>

      <!-- Dritte Reihe -->
      <h2 class="m-t-2">
        <span>Ausgaben</span>
      </h2>
      <el-row :gutter="20" type="flex">
        <el-col :xs="24" :sm="12">
          <el-card class="card--chart" v-loading="!outgoingsChartsDataLoaded">
            <BarChart
              :chart-data="outgoingsChartsData"
              :options="barChartOptions"
              :width="charts.width"
              :height="charts.height"
              v-if="outgoingsChartsDataLoaded"
            />
          </el-card>
        </el-col>

        <el-col :xs="24" :sm="12">
          <el-card class="card--chart" v-loading="!outgoingsCharts.category.loaded">
            <PieChart
              :chart-data="charts.outgoings.category.chartjs"
              :options="doughnutChartOptions"
              :width="charts.width"
              :height="charts.height"
              v-if="outgoingsCharts.category.loaded"
            />
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { sortBy } from 'lodash';
import BarChart from '@/components/Chart/BarChart';
import CardKPI from '@/components/Card/CardKPI';
import PieChart from '@/components/Chart/PieChart';
import DatePicker from '@/components/Datepicker/DatePicker';

export default {
  name: 'SalesIndex',

  components: {
    BarChart,
    CardKPI,
    DatePicker,
    PieChart
  },

  computed: {
    ...mapGetters({
      incomeChartsCustomersLoaded: 'report/incomeChartsCustomersLoaded',
      incomeChartsMonthLoaded: 'report/incomeChartsMonthLoaded',
      incomeChartsYearLoaded: 'report/incomeChartsYearLoaded',
      incomeTrend: 'report/incomeTrend',
      sumOpenInvoices: 'report/sumOpenInvoices',
      sumOpenVouchers: 'report/sumOpenVouchers',
      outgoingsChartsCategoryLoaded: 'report/outgoingsChartsCategoryLoaded',
      outgoingsChartsMonthLoaded: 'report/outgoingsChartsMonthLoaded',
      outgoingsChartsYearLoaded: 'report/outgoingsChartsYearLoaded',
      outgoingsTrend: 'report/outgoingsTrend'
    }),

    incomeCharts() {
      return this.$store.state.report.income;
    },

    incomeChartsData() {
      let result = this.charts.income.month.chartjs;
      if (this.charts.displayMode !== 'month') {
        result = this.charts.income.year.chartjs;
      }
      return result;
    },

    incomeChartsDataLoaded() {
      let result = this.incomeCharts.month.loaded;
      if (this.charts.displayMode !== 'month') {
        result = this.incomeCharts.year.loaded;
      }
      return result;
    },

    outgoingsCharts() {
      return this.$store.state.report.outgoings;
    },

    outgoingsChartsData() {
      let result = this.charts.outgoings.month.chartjs;
      if (this.charts.displayMode !== 'month') {
        result = this.charts.outgoings.year.chartjs;
      }
      return result;
    },

    outgoingsChartsDataLoaded() {
      let result = this.outgoingsCharts.month.loaded;
      if (this.charts.displayMode !== 'month') {
        result = this.outgoingsCharts.year.loaded;
      }
      return result;
    },

    percentageTotalNet() {
      return parseFloat(Number((this.totalSumNetMonth * 100) / 100000).toFixed(1));
    },

    topCustomersPerSumNet() {
      let orderedResults = [];
      const result = [];

      if (this.charts.income.customers.hasOwnProperty('simple')) {
        orderedResults = sortBy(this.charts.income.customers.simple, customer => {
          return customer.sumNet;
        });

        orderedResults = orderedResults.reverse();
        orderedResults.forEach((item, index) => {
          if (index < 6) {
            result.push(item);
          }
        });
      }

      return result;
    },

    topCategoriesPerSumNet() {
      let orderedResults = [];
      const result = [];

      if (this.charts.outgoings.category.hasOwnProperty('simple')) {
        orderedResults = sortBy(this.charts.outgoings.category.simple, category => {
          return category.sumNet;
        });

        orderedResults = orderedResults.reverse();
        orderedResults.forEach((item, index) => {
          if (index < 6) {
            result.push(item);
          }
        });
      }

      return result;
    },

    totalSumNetMonth() {
      let result = 0;
      if (this.incomeChartsData.hasOwnProperty('datasets') && this.incomeChartsData.datasets[0].hasOwnProperty('data')) {
        this.incomeChartsData.datasets[0].data.forEach(sum => {
          result += sum;
        });
      }
      return result;
    },

    totalSumVoucher() {
      let result = 0;
      if (this.charts.outgoings.month.hasOwnProperty('chartjs') && this.charts.outgoings.month.chartjs.datasets[0].hasOwnProperty('data')) {
        this.charts.outgoings.month.chartjs.datasets[0].data.forEach(sum => {
          result += sum;
        });
      }
      return result;
    }
  },

  data() {
    return {
      charts: {
        displayMode: 'month',
        height: 400,
        width: 400,
        income: {
          customers: { chartjs: { datasets: [{}] } },
          month: { chartjs: { datasets: [{}] } },
          year: { chartjs: { datasets: [{}] } }
        },
        outgoings: {
          category: { chartjs: { datasets: [{}] } },
          month: { chartjs: { datasets: [{}] } },
          year: { chartjs: { datasets: [{}] } }
        }
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              categorySpacing: 0,
              barPercentage: 0.7
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      },
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true
            }
          ],
          yAxes: [
            {
              stacked: true
            }
          ]
        }
      },
      doughnutChartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },

  mounted() {
    this.requestInitData();
  },

  methods: {
    onChangeDateRange(value) {
      const startDate = value[0] ? this.$moment(value[0]).unix() : null;
      const endDate = value[1] ? this.$moment(value[1]).unix() : null;

      this.$store.commit('report/SET_FILTERS', {
        startDate,
        endDate
      });

      this.requestInitData();
    },

    async requestInitData() {
      if (this.charts.displayMode === 'month') {
        this.charts.income.month = await this.$store.dispatch('report/GET_INCOME_CHART_PER_MONTH');
        this.charts.outgoings.month = await this.$store.dispatch('report/GET_VOUCHER_CHART_PER_MONTH');
      } else {
        this.charts.income.year = await this.$store.dispatch('report/GET_INCOME_CHART_PER_YEAR');
        this.charts.outgoings.year = await this.$store.dispatch('report/GET_VOUCHER_CHART_PER_YEAR');
      }

      this.charts.income.customers = await this.$store.dispatch('report/GET_INCOME_CHART_PER_CUSTOMERS');
      this.charts.outgoings.category = await this.$store.dispatch('report/GET_VOUCHER_CHART_PER_CATEGORY');

      await this.$store.dispatch('report/GET_ALL_INVOICES');
      await this.$store.dispatch('report/GET_ALL_VOUCHERS');

      await this.$store.dispatch('report/GET_INCOME_TREND');
      await this.$store.dispatch('report/GET_VOUCHER_TREND');
    }
  }
};
</script>

<style lang="scss" scoped>
.card--chart {
  min-height: 400px;
}

.kpi__trend {
  display: flex;

  &-icon {
    max-width: 2rem;
  }
}
</style>
