import { Doughnut } from 'vue-chartjs';
// const { reactiveProp } = mixins;

export default {
  // mixins: [mixins.reactiveProp],

  extends: Doughnut,

  props: ['chartData', 'options'],

  mounted() {
    this.init();

    // this.registerEvents();
  },

  methods: {
    init() {
      if (this.chartData) {
        this.renderChart(this.chartData, this.options);
      }
    }

    // registerEvents: function () {
    //   this.$on('Doughnut:update', (event, data) => {
    //     this.updateChart();
    //     // this.renderChart(this.data, this.options);
    //   });
    // },

    // updateChart: function () {
    //   this._chart.update();
    // }
  },

  watch: {
    chartData(newData) {
      if (this._chart) {
        this._chart.destroy();
      }
      this.init();
    }
  }
};
