import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  extends: Bar,

  mixins: [reactiveProp],

  props: {
    options: {
      type: Object,
      default() {
        return {
          responsive: true
        };
      }
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      if (this.chartData) {
        this.renderChart(this.chartData, this.options);
      }
    }
  },

  watch: {
    chartData() {
      if (this._chart) {
        this._chart.destroy();
      }
      this.init();
    }
  }
};
